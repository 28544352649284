(function($){
    "use strict";
    
    /**
     * Action "fc_action-confirm" : confirmation message after clicking an action
     */
    $(document).on('ready',function(){
    	$('body').on('click','.fc_action-confirm',function(e){
    		
    		var message = $(this).attr('data-message');
    		if (message === undefined || message === ''){
    			message= "Voulez-vous vraiment effectuer cette action?";
    		}
    		if (window.confirm(message)) { 
    		   return true;
    		} else {
    			e.stopPropagation();
    			return false;
    		}
    		
    	});
    });
})(jQuery);