
    	/**
    	 * Affiche une popup d'assistance
    	 * @returns
    	 */
      function displayAssistPopup(){
    	  
            $("#assistPopup").dialog({
                // add a close listener to prevent adding multiple divs to the document
                close: function(event, ui) {},
                title :"Besoin d'assistance?",
                width:400,
                height:200,
                modal: true
            });

      }