
/**
 * Transforme une chaine de caractère stockant une date au format français en objet Date
 * @param strDate
 * @returns Date
 */
function parseFrenchDate(strDate) {
	day = strDate.substring(0, 2);
	month = strDate.substring(3, 5);
	year = strDate.substring(6, 10);
	d = new Date();
	d.setFullYear(year);
	d.setMonth(month - 1);
	d.setDate(day);
	return d;
}

function fc_filterTags(unsafeString){
	if (unsafeString === undefined)
		return undefined;
	return unsafeString.replace(/</g, "&lt;").replace(/>/g, "&gt;");
}