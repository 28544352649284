// pour navigateurs autre que Firefox/Chrome : console.debug
//On créé l’objet console s’il n’existe pas (sous d’autre navigateur que Firebox & Chrome)
if(typeof console !== 'object') {
    console = {};
}
if((typeof console.debug) !== 'function')//On pourrait aussi vérifier pour console.info et console.log
{
     if(typeof opera === 'object') {//On redirige les appels vers opera.postError();
         console = {
            debug : function(){
                return opera.postError(arguments);
            },
            info : function(){
                this.debug('[INFO] ',arguments);
            },
            log : function(){
                this.debug('[LOG] ',arguments);
            },
            group : console.log,
            groupCollapsed : console.log,
            groupEnd : function(){
                return true;
            },
            trace : function(){
                return true;
            },
            error : function(){
                return true;
            }
        };
     }
     else {//Ne rien afficher sur les autres navigateurs
         console = {
            debug : function(){
                return true;
            },
            info : function(){
                return true;
            },
            log : function(){
                return true;
            },
            group : function(){
                return true;
            },
            groupCollapsed : function(){
                return true;
            },
            groupEnd : function(){
                return true;
            },
            trace : function(){
                return true;
            },
            error : function(){
                return true;
            }
        };
     }
}